<template>
  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
        <b-row>
            <b-col lg="6" sm="6">
                <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year_id"
                        slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="committee.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                        <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col lg="6" sm="12">
              <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="org_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('globalTrans.organization')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                    plain
                    v-model="committee.org_id"
                    :options="orgList"
                    id="org_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="6" sm="12">
                <ValidationProvider name="Committee Type"  vid="committee_type" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="committee_type"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('elearning_config.committee_type')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                        plain
                        v-model="committee.committee_type"
                        :options="committeeTypeList"
                        id="committee_type"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="12" sm="12" class="text-left mt-3 mb-2">
                <h4 class='complain-title pl-3'>{{ $t('elearning_config.course_coordinator') }}</h4>
            </b-col>
            <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('elearning_config.course_coordinator') }}  {{ $t('globalTrans.search') }}</h4>
        </template>
        </b-row>
        <b-row v-for="(detail, index) in committee.details" :key="index" class="mt-3">
            <b-col lg="3" md="3" sm="6" xs="6">
                <ValidationProvider name="Name (En)" vid="name_en" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="name_en"
                        slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.name_en') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                        id="name_en"
                        v-model="detail.name_en"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col lg="3" md="3" sm="6" xs="6">
                <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="name_bn"
                        slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.name_bn') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                        id="name_bn"
                        v-model="detail.name_bn"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                    </ValidationProvider>
            </b-col>
            <b-col lg="3" md="3" sm="6" xs="6">
                <ValidationProvider name="Designation (En)" vid="designation_en" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="designation_en"
                        slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.designation_en') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                        id="designation_en"
                        v-model="detail.designation_en"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col lg="3" md="3" sm="6" xs="6">
                <ValidationProvider name="Designation (Bn)" vid="designation_bn" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="designation_bn"
                        slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.designation_bn') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                        id="designation_bn"
                        v-model="detail.designation_bn"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col lg="3" md="3" sm="6" xs="6">
                <ValidationProvider name="Mobile Number" vid="mobile_no" rules="required|min:11|max:11">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="mobile_no"
                        slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.mobile_no') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            type="number"
                            id="mobile_no"
                            v-model="detail.mobile_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col lg="3" md="3" sm="6" xs="6">
                <ValidationProvider name="Email" vid="email">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="email"
                        slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.email') }}
                        </template>
                        <b-form-input
                            id="email"
                            v-model="detail.email"
                            :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col offset="1" xl="2" lg="2" sm="4" class="mt-5">
                <b-button v-show="index == committee.details.length-1" variant=" iq-bg-success" size="sm" @click="addItem()"><i class="ri-add-line m-0"></i> {{ $t('globalTrans.add') }}</b-button>
                <b-button v-show="index || ( !index && committee.details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
            </b-col>
        </b-row>
        <div class="row">
        <div class="col-sm-3"></div>
        <div class="col text-right">
            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
            &nbsp;
            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
        </div>
        </div>
    </b-form>
</ValidationObserver>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { masterCommitteeStore, masterCommitteeUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getEditingData()
      this.committee = tmp
    } else {
      this.committee = Object.assign({}, this.committee, {
        // org_id: this.$store.state.dataFilters.orgId
      })
    }
  },
  mounted () {
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      committee: {
        committee_type: 0,
        fiscal_year_id: 0,
        org_id: 0,
        details: [
          {
            name_en: '',
            name_bn: '',
            designation_en: '',
            designation_bn: '',
            mobile_no: '',
            email: ''
          }
        ]
      }
    }
  },
  computed: {
    orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    committeeTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.committeeType
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
  watch: {
    },
  methods: {
    addItem () {
      const obj = {
        name_en: '',
        name_bn: '',
        designation_en: '',
        designation_bn: '',
        mobile_no: '',
        email: ''
      }
      const key1 = parseInt(this.committee.details.length - 1)
     const item = this.committee.details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item.name_en === '' || item.name_bn === '' || item.designation_en === '' || item.designation_bn === '' || item.mobile_no === '') {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
        this.committee.details.push(obj)
      }
    },
    remove (key) {
      this.committee.details.splice(key, 1)
    },
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.committee.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${masterCommitteeUpdate}/${this.id}`, this.committee)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, masterCommitteeStore, this.committee)
      }

      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
