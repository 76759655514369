<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple bordered>
                            <b-tr>
                                <b-th style="width:10%">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                <b-td style="width:10%">{{ ($i18n.locale=='bn') ? venueMnagement.fiscal_name_bn : venueMnagement.fiscal_name }}</b-td>
                                <b-th style="width:10%">{{ $t('elearning_config.committee_type') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? venueMnagement.committee_name_bn : venueMnagement.committee_name }}</b-td>
                                <b-th style="width:10%">{{ $t('globalTrans.organization') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? venueMnagement.org_bn : venueMnagement.org }}</b-td>
                            </b-tr>
                    </b-table-simple>
                      <body-card>
                      <template v-slot:headerTitle>
                            <h4 class="card-title">{{ $t('elearning_config.course_coordinator') }} {{ $t('globalTrans.list') }}</h4>
                      </template>
                      </body-card>
                    <b-table-simple bordered>
                        <b-tr>
                            <b-th style="width:25%">{{ $t('globalTrans.name') }}</b-th>
                            <b-th style="width:25%">{{ $t('globalTrans.designation') }}</b-th>
                            <b-th style="width:25%">{{ $t('globalTrans.mobile_no') }}</b-th>
                            <b-th style="width:25%">{{ $t('globalTrans.email') }}</b-th>
                        </b-tr>
                        <b-tr v-for="(item,index) in venueMnagement.details" :key="index">
                            <b-td style="width:20%">{{ ($i18n.locale=='en') ? item.name_en : item.name_bn }}</b-td>
                            <b-td style="width:20%">{{ ($i18n.locale=='en') ? item.designation_en : item.designation_bn }}</b-td>
                            <b-td style="width:30%">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(item.mobile_no, { useGrouping: false }) }}</b-td>
                            <b-td style="width:20%">{{ item.email }}</b-td>
                        </b-tr>
                    </b-table-simple>
                  </b-col>
              </b-row>
            </template>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import BodyCard from '../../../../../components/BodyCard.vue'
export default {
    components: { BodyCard },
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.venueMnagement = this.item
  },
  data () {
    return {
      venueMnagement: [],
      slOffset: 1
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
