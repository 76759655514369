<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('elearning_config.course_coordinator') }}  {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="6" sm="12">
            <b-form-group
              label-for="committee_type"
            >
              <template v-slot:label>
                {{$t('elearning_config.fiscal_year')}}
              </template>
              <b-form-select
                plain
                id="fiscal_year_id"
                :options="fiscalYearList"
                v-model="search.fiscal_year_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
              label-for="committee_type"
            >
              <template v-slot:label>
                {{ $t('tradeFairConfig.committee_type') }}
              </template>
              <b-form-select
                plain
                id="committee_type"
                :options="committeeTypeList"
                v-model="search.committee_type"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('elearning_config.course_coordinator') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
            <b-button variant="primary btn_add_new" v-b-modal.modal-4 hide-footer @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
      </template>
      <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(status)="data">
                        <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <a href="javascript:" class="action-btn status" variant=" action-btn status mr-1"  title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="ri-eye-line"></i></a>
                        <a href="javascript:" class="action-btn edit" variant=" iq-bg-success mr-1" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-pencil-fill"></i></a>
                        <a href="javascript:" :variant="data.item.status === 2 ? ' btn-danger' : ' btn-success'" size="sm"
                        class="action-btn status" :title="data.item.status === 2 ? $t('globalTrans.inactive') : $t('globalTrans.active')" v-if="data.item.status === 1" @click="remove(data.item)"><i :class="data.item.status === 2  ? 'ri-toggle-line' : 'ri-toggle-fill'"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" v-if="data.item.status === 2" @click="remove(data.item)"><i class="fa fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-4" size="xl" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Form :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-5" size="lg" :title="$t('elearning_config.course_coordinator') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" hide-footer ok-only ok-variant="danger">
       <Details :item="item" :key="id"/>
    </b-modal>
  </div>
</template>
<script>
import Form from './Form'
import Details from './Details'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { masterCommitteeList, masterCommitteeToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      sortBy: '',
      search: {
        fiscal_year_id: 0,
        committee_type: 0,
        limit: 10
      },
      id: 0,
      item: '',
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
        { labels: 'elearning_config.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '20%' } },
        { labels: 'globalTrans.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '25%' } },
        { labels: 'elearning_config.committee_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '20%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 5, thStyle: { width: '20%' } }
      ],
      trainingTypeList: [],
      actions: {
        edit: true,
        toogle: false,
        delete: false,
        details: true
      }
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    this.search = Object.assign({}, this.search, {
      // org_id: this.$store.state.dataFilters.orgId
    })
    this.loadData()
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    committeeTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.committeeType
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_name_bn' },
          { key: 'org_bn' },
          { key: 'committee_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_name' },
          { key: 'org' },
          { key: 'committee_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('elearning_config.course_coordinator') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.course_coordinator') + ' ' + this.$t('globalTrans.modify')
    },
    orgList () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    }
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.item = item
    },
    remove (item) {
      this.changeStatus(trainingElearningServiceBaseUrl, masterCommitteeToggleStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(trainingElearningServiceBaseUrl, masterCommitteeList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data.data))
          this.paginationData(response.data, this.search.limit)
        } else {
          this.$store.dispatch('setList', [])
          this.paginationData([])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const fiscalList = this.$store.state.CommonService.commonObj.fiscalYearList
      const committeeTypeList = this.$store.state.TrainingElearning.commonObj.committeeType
      const listData = data.map((item, index) => {
        const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
        const orgData = {}
        if (typeof orgObj !== 'undefined') {
          orgData.org = orgObj.text_en
          orgData.org_bn = orgObj.text_bn
        } else {
          orgData.org = ''
          orgData.org_bn = ''
        }
        const fiscalObj = fiscalList.find(fiscal => fiscal.value === item.fiscal_year_id)
        const committeeObj = committeeTypeList.find(committee => committee.value === parseInt(item.committee_type))
        const fiscalData = { fiscal_name: fiscalObj !== undefined ? fiscalObj.text_en : '', fiscal_name_bn: fiscalObj !== undefined ? fiscalObj.text_bn : '' }
        const committeeData = { committee_name: committeeObj !== undefined ? committeeObj.text_en : '', committee_name_bn: committeeObj !== undefined ? committeeObj.text_bn : '' }
        return Object.assign({}, item, committeeData, fiscalData, { index: index }, orgData)
      })
      return listData
    }
  }
}
</script>
